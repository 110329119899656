.header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .left .logoLink {
  display: flex;
  align-items: center;
}

.header .left .logoLink svg {
  font-size: 2.5rem;
}

@media (max-width: 960px) {
  .header {
    padding: 1rem 0.5rem;
  }
}

.logo {
  margin-left: 0.5em;
}

@media (max-width: 960px) {
  .logo {
    margin-left: 0;
  }
}