.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 4em;
}

.links {
  display: flex;
  align-items: center;
  gap: 1em;
}

.copyRight {
  color: #053c34;
}

@media (max-width: 600px) {
  .footer {
    flex-direction: column;
    padding: 0;
    margin-top: 2em;
  }
}

@media (max-width: 386px) {
  .footer {
    font-size: 0.9em;
  }
}
