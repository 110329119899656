.textBg {
  background: var(--primary-color);
  height: 100%;
  width: auto;
  transform: rotate(-5deg);
  z-index: 1;
}

.textFg {
  color: #ffffff;
  z-index: 2;
  padding: 0.2rem 0.4rem;
}
.FaqList {
  display: grid;
  align-self: stretch;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  gap: 1rem;
  .card {
    padding: 0.5rem;
    background: #ffe7d5;
    .rowHeader {
      position: relative;
      .linkIcon {
        position: absolute;
        left: -30px;
        top: 0px;
        opacity: 0;
        color: #ffffff;
        fill: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    &:hover {
      .linkIcon {
        opacity: 1;
      }
    }
  }
}
