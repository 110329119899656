.container {
  // margin: 0.8rem 0;
  padding: 0.7rem 1.55rem;
  border-radius: 4px;
  &:hover {
    background-color: var(--faq-hover-color);
  }
}
.selected {
  background-color: var(--faq-hover-color);
}
.header {
  font-size: 1rem;
  color: var(--text-color);
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: flex-start;
  cursor: pointer;
  font-weight: 600;
  margin: 0.3rem 0;

  .icon {
    font-size: 1.2rem;
    margin-right: 5px;
    fill: var(--icon-color);
  }
}
.noCP {
  cursor: unset;
}
.iconOnEnd {
  justify-content: space-between;
}
.icon {
  justify-self: flex-end;
}
.body {
  color: var(--subtitle-color);
  code {
    font-size: 0.9rem;
    color: var(--text-light-color);
    background-color: var(--light-grey);
    border-radius: 4px;
    padding: 0.2rem 4px;
  }
}
.active {
  color: var(--primary-main-color);
}
.fullBody {
  margin-left: unset;
  cursor: default;

  & > div > p {
    margin: unset;
    padding: 14px 0;
  }
}
